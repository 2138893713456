import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/config/environments/environment';
import { CastingData } from 'src/app/config/constant';
@Injectable({
  providedIn: 'root'
})
export class CarousalService {
  constructor(private http: HttpClient,
  ) { }


  setCarousalStorage(propertyName,value){
    let carousalInfo = JSON.parse(localStorage.getItem('carousalInfo'));
    carousalInfo[propertyName] = value;
    localStorage.setItem('carousalInfo', JSON.stringify(carousalInfo));
  }

  get CarousalStorage() {
    return JSON.parse(localStorage.getItem('carousalInfo'))
  }


  getScreenDetail(customerId){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingscreens?customerId=${customerId}`);
  }

  getAqiData(body){
    const page = 0;
    const size = 500;
    const type = body.type;
    const ids = body.ids;
    return this.http.get(`${environment.hygieneApiUrl}api/v1/work-order/dashboard/aqiListTvoc?page=${page}&size=${size}&type=${type}&ids=${ids}&order=DESC&colorAndStatus=${body.colorAndStatus}&dateTimeType=${body.dateTimeType}&noOfDays=${body.noOfDays}&showData=${body.showData}&customerId=${body.customerId}`);
    // return this.http.get(`${environment.hygieneApiUrl}api/v1/work-order/dashboard/aqiListTvoc?page=${page}&size=${size}&type=${type}&ids=${ids}&order=DESC`);
  }

  getAtpData(body){
    const page = 0;
    const size = 500;
    const type = body.type;
    const ids = body.ids;
    return this.http.get(`${environment.hygieneApiUrl}api/v1/work-order/dashboard/atpList?page=${page}&size=${size}&type=${type}&ids=${ids}&order=DESC&colorAndStatus=${body.colorAndStatus}&dateTimeType=${body.dateTimeType}&noOfDays=${body.noOfDays}&showData=${body.showData}&customerId=${body.customerId}`);
    // return this.http.get(`${environment.hygieneApiUrl}api/v1/work-order/dashboard/atpList?page=${page}&size=${size}&type=${type}&ids=${ids}&order=DESC`);
  }

  getAtp300Data(body){
    const ids = body.ids;
    return this.http.get(`${environment.hygieneApiUrl}api/v1/hygiena/castingAtpList?colorAndStatus=${body.colorAndStatus}&dateTimeType=${body.dateTimeType}&noOfDays=${body.noOfDays}&showData=${body.showData}&customerId=${body.customerId}&branchId=${ids}`);
    // return this.http.get(`${environment.hygieneApiUrl}api/v1/work-order/dashboard/atpList?page=${page}&size=${size}&type=${type}&ids=${ids}&order=DESC`);
  }


//-----------------   weather Api  -------------------

  getLocation(branchId){  
    return this.http.get(`${environment.customerApiUrl}api/v1/branch/address/${branchId}`);
  }

  getLatLong(body){
    body.appid = '2ae68eb7f9d4b91605f2537f22abc955'
    return this.http.get(`https://api.openweathermap.org/geo/1.0/direct?q=${body.address}&limit=1&appid=${body.appid}`)
  }

  getWeatherCurrentData(body){
    body.appid = '2ae68eb7f9d4b91605f2537f22abc955'
    return this.http.get(`https://api.openweathermap.org/data/2.5/weather?lat=${body.lat}&lon=${body.lon}&appid=${body.appid}`)
  }

  // getWeatherUpdate(body){
  //   body.appid = '2ae68eb7f9d4b91605f2537f22abc955'
  //   return this.http.get(`https://api.openweathermap.org/data/2.5/onecall?lat=${body.lat}&lon=${body.lon}&units=${body.units}&appid=${body.appid}`)
  // }

  // getWeatherUpdateHistoric(body,date){
  //   body.appid = '439d4b804bc8187953eb36d2a8c26a02';
  //   body.date = date;
  //   return this.http.get(`https://openweathermap.org/data/2.5/onecall/timemachine?lat=${body.lat}&lon=${body.lon}&dt=${date}&units=${body.units}&appid=${body.appid}`)
  // }

  getWeatherAirPollutionHistory(body,start,end){
    body.appid = '2ae68eb7f9d4b91605f2537f22abc955';
    body.start = start;
    body.end = end;
    return this.http.get(`https://api.openweathermap.org/data/2.5/air_pollution/history?lat=${body.lat}&lon=${body.lon}&start=${body.start}&end=${body.end}&appid=${body.appid}`)
  }

  getWeatherAirPollution(body){
    body.appid = '2ae68eb7f9d4b91605f2537f22abc955'
    return this.http.get(`https://api.openweathermap.org/data/2.5/air_pollution?lat=${body.lat}&lon=${body.lon}&appid=${body.appid}`)
  }

  getHistoricalWeatherAirPollution(body){
    return this.http.get(`https://api.openweathermap.org/data/2.5/air_pollution/history?lat=${body.lat}&lon=${body.lon}&start=${body.start}&end=${body.end}&appid=${body.appid}`)
  }

  getHistoricalWeather(body){
    return this.http.get(`https://api.openweathermap.org/data/2.5/history/city?lat=${body.lat}&lon=${body.lon}&type=hour&start=${body.start}&end=${body.end}&appid=${body.appid}`)
  }



  getPrecipitation(body){
    body.appid = '2ae68eb7f9d4b91605f2537f22abc955'
    return this.http.get(`https://api.openweathermap.org/data/2.5/forecast?q=${body.address}&appid=${body.appid}`)
  }

  //-----------------  casting configuration API  -------------------

  checkCastingAccess(){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingscreens/getScreenAvailability`)
  }

  checkCMSCastingAccess(customerId){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingscreens/getCmsScreenAvailability?customerId=${customerId}`)
  }

  getCastingList(page,size,searchtext,sortBy,order,status){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingscreens/getAllCustomersScreenStatus?page=${page}&size=${size}&by=${sortBy}&order=${order}&qry=${searchtext}&status=${status}`)
  }

  getCastingScreenDetail(customerId){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingscreens/getforcustomer?customerId=${customerId}`)
  }

  getCMSCastingDataDetail(customerId){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingscreens/getcmscastingscreen?customerId=${customerId}`)
  }


  getCastingDataSetting(customerId){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingdatasetting?customerId=${customerId}`)
  }
  

  setCastingDataSetting(body){
    return this.http.put(`${environment.customerApiUrl}api/v1/castingdatasetting?customerId=${body.customerId}&daycount=${body.daycount}`,null);
  }

  updateCastingData(customerId,body){
    return this.http.put(`${environment.customerApiUrl}api/v1/castingscreens/updatecustomersscreenStatus?customerIds=&customerId=${customerId}`,body);
  }

  updateCMSCastingData(body){
    return this.http.put(`${environment.customerApiUrl}api/v1/castingscreens/updatecmscastingscreen?colorAndStatus=${body.colorStatus}&parameterList=${body.parameterList}&cmsDashboardParams=${body.dashboardParameterList}&cmsScreens=${body.slideList}&branchId=${body.branchId}&buildingList=${body.buildingList}&customerId=${body.customerId}`,{});
  }

  updateListToggle(customerId,isCastingAllowed,isCmsCastingAllowed){
    return this.http.put(`${environment.customerApiUrl}api/v1/castingscreens/setcustomerscreenavailability?customerId=${customerId}&isCastingAllowed=${isCastingAllowed}&isCmsCastingAllowed=${isCmsCastingAllowed}`,null)
  }

  getCastingCalendarDetail(customerId){
    return this.http.get(`${environment.hygieneApiUrl}api/v1/hygieneholidaycalender/${customerId}`)
  }

  updateCastingCalendarDetail(data){
    return this.http.post(`${environment.hygieneApiUrl}api/v1/hygieneholidaycalender/update`,data)
  }



}
